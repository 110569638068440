.mirror {
  position: relative;
  min-height: 100px;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.error {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  background: rgba(207, 216, 220, 0.68);
  color: #C2185B;
}
.wrap {
  margin: 0 auto;
  max-width: 720px;
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #000;
  color: #e0e0e0;
  user-select: none;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mirror {
  height: 70vh;
}

.draws {
  border-top: 1px dashed #fff;
  width: 100%;
  flex: 1;
}

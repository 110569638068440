.spin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.spin-icon {
  display: block;
  margin: 0 auto;
  padding: 30px 0;
  width: 48px;
}

.empty {
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding: 30px 0;
}

.scroll {
  :global {
    .glasses-name {
      display: none;
    }

    .slick-slide {
      transform: scale(0.82);
    }

    .slick-center {
      transform: scale(1);

      .glasses-name {
        display: block;
      }
    }
  }
}

.home {
  text-align: center;
}

.video-page {
  margin: 0 auto;
  max-width: 720px;
  width: 100vw;
}

.camera-wrap {
  position: relative;
}

img,
video,
canvas {
  max-width: 100%;
  height: auto;
}

.video-cover {
  position: absolute;
  top: 0;
  left: 0;
}

.cn-wrap {
  position: relative;
}

.cn-media {
  position: relative;
}

.cn-cover {
  position: absolute;
  left: 0;
  top: 0;
}

.nav {
  line-height: 3em;
}

.nav>li {
  list-style: lao;
}

.fps_meter input {
  width: 80px;
}
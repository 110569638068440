.draw {
  position: relative;
  font-size: 16px;
}

.glasses {
  margin-top: 12px;
}

.go-detail {
  position: fixed;
  right: 12px;
  bottom: 12px;
  display: block;
  padding: 0 20px;
  height: 40px;
  border-radius: 24px;
  background: #00acc1;
  background-image:
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2),
      rgba(0, 0, 0, 0.1)
    );
  box-shadow: 0 1px 40px 3px rgba(0, 172, 193, 0.68);
  color: #fff;
  text-decoration: none;
  line-height: 38px;
  cursor: pointer;

  &::hover {
    opacity: 0.8;
  }
}
